import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TProduct, getProductGroup } from "@/api";
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  ImageField,
  Labeled,
  Link,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import { SimpleChipField } from "../../components/SimpleChipField";
import {
  ADMINS,
  BRANDS,
  CATEGORIES,
  PRODUCT_GROUPS,
} from "../../config/resources";
import {
  productLocationTypeChoices,
  productTypeChoices,
  supplierProductApproveStatus,
} from "../../data/products";
import { useAdmin } from "../../hooks/admin";
import { ChangeGroupButton } from "./actions/change-group";
import { ApproveButton } from "./actions/approveButton";
import { KitProductsReferenceArrayField } from "./fields/kit-products";
import { TaxClassificationProductField } from "./fields/tax-classification";
import { LocationProductField } from "./fields/location";
import { ProductLogs } from "./fields/logs";
import { ProductSuppliersField } from "./fields/suppliers";
import useSWR from "swr";
import { isProd } from "@/constants/env";
import { country } from "@/config/country";

const ProductShowActions = () => {
  const { hasPermission } = useAdmin();
  const record = useRecordContext();

  return (
    <TopToolbar>
      <ApproveButton />
      <ChangeGroupButton />
      {hasPermission("product.update") && <EditButton />}
      {record && hasPermission("product.delete") && <DeleteWithConfirmButton />}
    </TopToolbar>
  );
};

export const ProductShow = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("product.view")) return null;

  return (
    <Show actions={<ProductShowActions />}>
      <AlertProductGroup />
      <TabbedShowLayout>
        <Tab label="Principal">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Typography>General</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SimpleShowLayout>
                        <TextField source="id" />
                        <FunctionField
                          source="type"
                          render={(record: any) =>
                            productTypeChoices.find((t) => t.id === record.type)
                              ?.name
                          }
                        />
                        <TextField source="name" />
                        <WithRecord
                          render={(product: TProduct) => (
                            <Labeled label="Enlace a la web">
                              <UrlField
                                source="link"
                                record={{
                                  link:
                                    (isProd
                                      ? country.code === "mx"
                                        ? "https://seeri.store/es-MX/productos/"
                                        : "https://seeri.store/productos/"
                                      : "https://seeri-app.dev.seeri.co/productos/") +
                                    product.slug,
                                }}
                                target="_blank"
                              />
                            </Labeled>
                          )}
                        />

                        <FunctionField
                          source="approvedStatus"
                          render={(group: any) => (
                            <Chip
                              color={
                                ["PENDING", "IN_REVIEW"].includes(
                                  group?.approvedStatus
                                )
                                  ? "error"
                                  : "APPROVED" === group?.approvedStatus
                                  ? "success"
                                  : "warning"
                              }
                              label={
                                supplierProductApproveStatus.find(
                                  ({ id }) => id === group.approvedStatus
                                )?.name ?? "Sin estado"
                              }
                              variant="outlined"
                            />
                          )}
                        />
                        <FunctionField
                          source="productOptions"
                          render={(record: TProduct) =>
                            Object.entries(record.productOptions || {}).map(
                              ([name, value]) => (
                                <Box key={name}>
                                  <>
                                    {name}: {value} <br />
                                  </>
                                </Box>
                              )
                            )
                          }
                        />
                        <ReferenceField
                          source="brandId"
                          reference={BRANDS}
                          link={hasPermission("brand.view") ? "show" : false}
                        >
                          <TextField source="name" />
                        </ReferenceField>

                        <ReferenceField
                          source="categoryId"
                          reference={CATEGORIES}
                          link={hasPermission("category.view") ? "show" : false}
                        >
                          <TextField source="name" />
                        </ReferenceField>

                        <NumberField source="retailPrice" emptyText="-" />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SimpleShowLayout>
                        <TextField source="sku" />
                        <TextField source="barCode" />
                        <NumberField source="unitType" emptyText="-" />
                        <BooleanField
                          source="partialUnitsAllowed"
                          emptyText="-"
                        />
                        <Stack direction={"row"} spacing={2}>
                          <Labeled>
                            <TextField source="height" />
                          </Labeled>
                          <Labeled>
                            <TextField source="width" />
                          </Labeled>
                          <Labeled>
                            <TextField source="length" />
                          </Labeled>
                          <Labeled>
                            <TextField source="weight" />
                          </Labeled>
                        </Stack>
                        <BooleanField source="status" />
                        <BooleanField source="featured" />
                      </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={12}>
                      <Labeled fullWidth>
                        <KitProductsReferenceArrayField />
                      </Labeled>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <Box sx={{ position: "relative", height: "100%" }}>
                    <Box
                      sx={{
                        inset: 0,
                        position: { sx: "static", md: "absolute" },
                        overflowY: "auto",
                      }}
                    >
                      <Typography>Historial</Typography>
                      <ProductLogs />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography>Distribuidores</Typography>
                  <Labeled fullWidth>
                    <ProductSuppliersField />
                  </Labeled>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <SimpleShowLayout>
                    <TextField source="description" />
                    <RichTextField source="longDescription" />
                  </SimpleShowLayout>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Tab>

        {hasPermission("product.show.tab.finance") && (
          <Tab label="Finanzas">
            <NumberField source="taxPercentage" />
            <TaxClassificationProductField />
            <ArrayField source="taxesType">
              <SingleFieldList linkType={false}>
                <SimpleChipField />
              </SingleFieldList>
            </ArrayField>
          </Tab>
        )}

        <Tab label="Categorías visibles">
          <ReferenceArrayField
            label="Categorías visibles"
            reference={CATEGORIES}
            source="visibleCategories"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>

        <Tab label="Ubicación">
          <FunctionField
            source="locationType"
            render={(record: any) =>
              productLocationTypeChoices.find(
                (t) => t.id === record.locationType
              )?.name
            }
          />
          <LocationProductField />
        </Tab>

        <Tab label="Imágenes">
          <ArrayField source="images">
            <Datagrid bulkActionButtons={false}>
              <ImageField
                source="src"
                sx={{
                  "& .RaImageField-image": {
                    width: "100%",
                  },
                }}
              />
              <TextField source="src" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Keywords">
          <ArrayField source="keyWords">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
        </Tab>

        <Tab label="Miscelánea">
          <DateField source="createdAt" showTime />
          <ReferenceField
            source="createdBy"
            reference={ADMINS}
            link={hasPermission("admin.view") ? "show" : false}
          >
            <TextField source="email" />
          </ReferenceField>
          <DateField source="updatedAt" showTime />
          <ReferenceField
            source="updatedBy"
            reference={ADMINS}
            link={hasPermission("admin.view") ? "show" : false}
          >
            <TextField source="email" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const AlertProductGroup = () => {
  const record = useRecordContext();
  const { data: group } = useSWR(
    () => [record.groupProductId, "seller-debt"],
    ([id]) => getProductGroup(id as string) as any
  );

  if (!group || !["IN_REVIEW", "PENDING"].includes(group?.approvedStatus))
    return null;

  return (
    <Alert severity="warning">
      Este producto esta asociado a un grupo que se encuentra en revisión, por
      lo que los cambios en este producto no serán visibles hasta que el grupo
      sea aprobado.
      <br />
      <FunctionField
        source="images"
        render={(record: TProduct) =>
          record.images ? (
            <Link to={`/${PRODUCT_GROUPS}/${group.id}/show`}>Ver grupo</Link>
          ) : null
        }
      />
    </Alert>
  );
};
