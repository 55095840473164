import { Refresh, ToggleOn, ToggleOffOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { AxiosError } from "@/api";
import { FC } from "react";
import {
  AutocompleteInput,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
  useRecordContext,
  useUpdate,
} from "react-admin";
import { ClearFiltersButton } from "../../components/ClearFiltersButton";
import { ModalImageField } from "../../components/elements/ModalImageField";
import { BRANDS, PRODUCTS, SUPPLIERS } from "../../config/resources";
import {
  productTypeChoices,
  supplierProductApproveStatus,
} from "../../data/products";
import { useAdmin } from "../../hooks/admin";
import { CategoryAutocompleteInput } from "../shared/category";
import { exporter } from "./export";
import { ProductsImportButton } from "./import";
import { ProductsListExport } from "./actions/list-export";

const ProductStatusToggle: FC<{ source?: string }> = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const canToggleStatus =
    record?.status || record.locationType === "SUPPLIER_STORAGE";

  const [update, { isLoading }] = useUpdate(
    PRODUCTS,
    {
      id: record?.id,
      data: { ...record, status: !record.status },
      previousData: record,
    },
    {
      mutationMode: "pessimistic",
      onError: (error: AxiosError) =>
        notify(error.message ?? "Ocurrió un error", { type: "error" }),
    }
  );

  return (
    <IconButton
      color="primary"
      title={record.status ? "Desactivar" : "Activar"}
      onClick={(e) => {
        e.stopPropagation();
        update();
      }}
      disabled={isLoading || !canToggleStatus}
    >
      {isLoading ? (
        <Refresh className={isLoading ? "rotate-icon" : ""} />
      ) : record.status ? (
        <ToggleOn fontSize="large" />
      ) : (
        <ToggleOffOutlined fontSize="large" />
      )}
    </IconButton>
  );
};

const productFilters = [
  <NullableBooleanInput source="status" alwaysOn />,
  <ReferenceInput
    source="supplierId"
    reference={SUPPLIERS}
    sort={{ field: "name", order: "ASC" }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      label="Proveedor"
      optionText="name"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
  // <TextInput source="sku" alwaysOn />,
  <TextInput source="name" label="Buscar..." alwaysOn />,
];

const ProductActions = () => {
  const { hasPermission } = useAdmin();
  const { total } = useListContext();

  return (
    <TopToolbar>
      <ClearFiltersButton />
      <FilterButton filters={productFilters} />
      {hasPermission("product.create") && <CreateButton />}
      {hasPermission("product.export") && <ProductsListExport />}
      {hasPermission("product.export") && (
        <ExportButton
          disabled={total === 0}
          maxResults={100_000}
          exporter={exporter}
        />
      )}
      <ProductsImportButton />
    </TopToolbar>
  );
};

export const ProductList = () => {
  const { hasPermission } = useAdmin();

  if (!hasPermission("product.list")) return null;

  return (
    <List
      filters={productFilters}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20]} />}
      hasCreate={hasPermission("product.create")}
      actions={<ProductActions />}
      queryOptions={{ meta: { mode: "search" } }}
    >
      <Datagrid
        rowClick={hasPermission("product.view") ? "show" : undefined}
        bulkActionButtons={false}
      >
        <FunctionField
          label="Foto"
          render={() => (
            <ModalImageField
              source="images.0.src"
              maxHeight={80}
              maxWidth={80}
            />
          )}
        />
        <TextField source="sku" />

        <ReferenceField source="supplierId" reference={SUPPLIERS} link={"show"}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          source="brandId"
          reference={BRANDS}
          link={hasPermission("brand.view") ? "show" : false}
        >
          <TextField source="name" />
        </ReferenceField>

        <TextField source="name" />
        <NumberField source="wholesalePrice" />
        <DateField source="createdAt" />
        <FunctionField
          source="status"
          render={(product: any) =>
            hasPermission("product.update.status") &&
            !["PENDING", "IN_REVIEW"].includes(product.approvedStatus) ? (
              <ProductStatusToggle source="status" />
            ) : (
              <BooleanField source="status" />
            )
          }
        />
      </Datagrid>
    </List>
  );
};
