import { ExpandMore, Refresh, WidgetsOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  Stack,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import {
  AxiosError,
  TPaymentProvider,
  createPrePurchaseV5,
  type TPaymentMethod,
} from "@/api";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { type FC, type ReactNode, useEffect } from "react";
import {
  Button,
  Create,
  Toolbar,
  required,
  TextInput,
  SaveButton,
  SimpleForm,
  RadioButtonGroupInput,
  useFormGroup,
  FormGroupContextProvider,
  BooleanInput,
  NumberInput,
} from "react-admin";
import { useFormState, useFormContext, useWatch } from "react-hook-form";
import useSWR from "swr";

import { getApplyCouponEnabled } from "../../../config/remote";
import { useAdmin } from "../../../hooks/admin";
import useDebounce from "../../../hooks/debounce";
import { SellerDialog } from "./seller-form-dialog";
import { ClientOption } from "./client";
import {
  PurchaseProductBrandArrayInput,
  PurchaseProductsArrayInput,
} from "./products";
import { PurchaseSellerAutocomplete } from "./seller";
import { prePurchaseAtom, prePurchaseErrorAtom } from "./store";
import {
  refreshSeeriApiConfig,
  setUserTypeApiConfig,
} from "../../../context/seeri-api";
import { LimitHeader } from "../../../components/LimitHeader";
import { BillingAdressDropdown } from "./billing-address";
import { Pricing } from "../../../components/purchase/Pricing";
import { purchaseFormAccordion } from "./atoms";
import { country } from "@/config/country";
import { SEERI_SUPPLIER_ID } from "@/constants/env";
import { i18nProvider } from "../../../providers/i18n";
import { PURCHASES } from "@/config/resources";

const PrePurchaseListener = () => {
  const setPrePurchase = useSetAtom(prePurchaseAtom);
  const setPrePurchaseError = useSetAtom(prePurchaseErrorAtom);
  const { isValid, isValidating } = useFormState();

  const sellerId = useWatch({ name: "sellerId" });
  const supplierId = useWatch({ name: "supplierId" });
  const userType = useWatch({ name: "userType" });

  const isForSeller = useWatch({ name: "isForSeller" });
  const products = useWatch({ name: "products" });
  const paymentMethod = useWatch({ name: "paymentMethod" });
  const paymentProvider = useWatch({ name: "paymentProvider" });
  const couponCode = useWatch({ name: "couponCode" });
  const ownShipping = useWatch({ name: "ownShipping" });
  const shippingPrice = useWatch({ name: "shippingPrice" });
  const debouncedCouponCode = useDebounce(couponCode, 1000);

  useEffect(() => {
    if (!isValid) {
      setPrePurchase(null);
      setPrePurchaseError(null);
    }
  }, [isValid, setPrePurchase, setPrePurchaseError]);

  useEffect(() => {
    setUserTypeApiConfig(userType);
  }, [userType]);

  useEffect(() => {
    return () => {
      refreshSeeriApiConfig();
    };
  }, []);
  useSWR(
    !isValidating &&
      products &&
      products.length > 0 &&
      isValid && {
        sellerId,
        isForSeller,
        products,
        paymentMethod,
        paymentProvider,
        ...(!ownShipping
          ? { shipping: shippingPrice >= 0 ? shippingPrice : 0 }
          : {}),
        couponCode: debouncedCouponCode,
        supplierId,
      },
    createPrePurchaseV5,
    {
      onSuccess: (value) => {
        setPrePurchase(value);
        setPrePurchaseError(null);
      },
      onError: (error) => {
        setPrePurchaseError(error);
        setPrePurchase(null);
      },
    }
  );

  return null;
};

const PurchaseCreateToolbar = () => {
  return (
    <Toolbar>
      <SaveButton type="button" />
    </Toolbar>
  );
};

export const PurchaseCreate = () => {
  const { hasPermission } = useAdmin();
  if (!hasPermission("purchase.create")) return null;

  return (
    <>
      <SellerDialog />
      <Create redirect="show">
        <SimpleForm
          mode="onChange"
          toolbar={<PurchaseCreateToolbar />}
          warnWhenUnsavedChanges
          defaultValues={{
            countryCode: country.code,
            clientTarget: "own",
            isForSeller: true,
            ownShipping: true,
            paymentMethod: "ONLINE",
            supplierId: SEERI_SUPPLIER_ID,
          }}
        >
          <PrePurchaseListener />
          <Grid container spacing={4} marginTop={2}>
            <Grid item xs={12} md={7}>
              <AccordionPurchase />
            </Grid>
            <Grid item xs={12} md={5}>
              <DetailsWidget />
              <ProductLimits />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};

const AccordionPurchase = () => {
  const { hasPermission } = useAdmin();
  const [expanded, setExpanded] = useAtom(purchaseFormAccordion);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <Typography variant="h5" component="h5" mb={2}>
        Formulario de creación
      </Typography>
      <Accordion
        expanded={expanded === "seller"}
        onChange={handleChange("seller")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Vendedor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PurchaseSellerAutocomplete />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "client"}
        onChange={handleChange("client")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Dirección de envío</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientOption />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "billingAddress"}
        onChange={handleChange("billingAddress")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Dirección de facturación</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BillingAdressDropdown />
        </AccordionDetails>
      </Accordion>
      <FormGroupContextProvider name="products">
        <Accordion
          expanded={expanded === "products"}
          onChange={handleChange("products")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <AccordionSectionTitle name="products">
              Productos
            </AccordionSectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <PurchaseProductsArrayInput includeCity />
          </AccordionDetails>
        </Accordion>
      </FormGroupContextProvider>
      <FormGroupContextProvider name="paymentMethod">
        <Accordion
          expanded={expanded === "paymentMethod"}
          onChange={handleChange("paymentMethod")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <AccordionSectionTitle name="paymentMethod">
              Método de pago
            </AccordionSectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <PaymentMethodRadio />
          </AccordionDetails>
        </Accordion>
      </FormGroupContextProvider>

      {hasPermission("purchase.create.shipping") && (
        <Accordion
          expanded={expanded === "shipping"}
          onChange={handleChange("shipping")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Costo de envío</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShippingInputs />
          </AccordionDetails>
        </Accordion>
      )}

      {getApplyCouponEnabled() && hasPermission("purchase.create.coupon") && (
        <Accordion
          expanded={expanded === "coupon"}
          onChange={handleChange("coupon")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Cupón</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CouponInput />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

const AccordionSectionTitle: FC<{ name: string; children: ReactNode }> = ({
  children,
  name,
}) => {
  const formGroupState = useFormGroup(name);
  return (
    <Typography
      color={
        !formGroupState.isValid && formGroupState.isDirty ? "error" : "inherit"
      }
    >
      {children}
    </Typography>
  );
};

const PaymentMethodRadio = () => {
  const paymentMethod = useWatch({ name: "paymentMethod" });
  const paymentProvider = useWatch({ name: "paymentProvider" });
  const { setValue } = useFormContext();
  const { role, hasPermission } = useAdmin();
  const prePurchase = useAtomValue(prePurchaseAtom);
  const t = (key: string) => {
    const errors: Record<string, string> = {
      AMOUNT_TO_PAY_IS_LOWER_THAN_REQUIRED:
        "El monto a pagar es menor que el requerido",
      AMOUNT_TO_PAY_IS_UPPER_THAN_REQUIRED:
        "El monto a pagar es mayor al requerido",
      CLIENT_ID_IS_REQUIRED: "El documento de identidad es requerido",
      KALTO_ONBOARDING_IS_REQUIRED: "Onboarding de Kalto requerido",
      DISABLED_BY_DEBT: "Deshanilitado por deuda",
      DISABLED_BY_REJECTIONS: "Desabilitado por rechazos",
      NOT_ENOUGH_BALANCE: "El usuario no cuenta con el cupo suficiente",
      DISABLED_BY_SUPPLIER: "Deshabilitado por el distribuidor",
    };

    return errors[key];
  };

  let choices: TPaymentMethod[] = [];

  let providerChoises: TPaymentProvider[] = [];

  let unselectableChoices: TPaymentMethod[] = [];

  if (prePurchase) {
    // Filter: pre purchase
    choices = prePurchase.paymentMethods.filter(
      (p) => p.isValid && p.code !== "PARTIAL_PAYMENT"
    );
    unselectableChoices = prePurchase.paymentMethods.filter(
      (p) => !p.isValid && p.code !== "PARTIAL_PAYMENT"
    );

    // Filter: roles and permissions

    // UPON_DELIVERY_SEERI
    choices = choices.filter(
      (p) =>
        p.code !== "UPON_DELIVERY_SEERI" ||
        hasPermission("purchase.create.uponDeliverySeeri") ||
        role.name === "Admin"
    );

    if (paymentMethod) {
      providerChoises =
        choices.find((c) => c.code === paymentMethod)?.providers ||
        providerChoises;
    }
  }

  useEffect(() => {
    if (prePurchase && !choices.some((p) => p.code === paymentMethod)) {
      setValue("paymentMethod", null);
    }
    if (
      prePurchase &&
      !providerChoises.find((p) => p.code == paymentProvider)
    ) {
      let value = null;
      if (providerChoises.length == 1) {
        value = providerChoises[0].code;
      }
      setValue("paymentProvider", value);
    }
  }, [
    prePurchase,
    paymentMethod,
    paymentProvider,
    choices,
    providerChoises,
    setValue,
  ]);

  return (
    <>
      <RadioButtonGroupInput
        source="paymentMethod"
        optionText={(choice) => {
          return i18nProvider.translate(
            `resources.${PURCHASES}.fields.paymentMethods.${choice.code}`
          );
        }}
        optionValue="code"
        choices={choices}
        disabled={!prePurchase}
      />
      {!!unselectableChoices.length && (
        <RadioButtonGroupInput
          source="paymentMethod"
          label="Métodos de pago no disponibles"
          optionValue="code"
          optionText={(choice) => {
            if (!choice?.errors?.length) {
              return i18nProvider.translate(
                `resources.${PURCHASES}.fields.paymentMethods.${choice.code}`
              );
            }

            return (
              <Tooltip title={t(choice?.errors[0])}>
                <div>
                  {i18nProvider.translate(
                    `resources.${PURCHASES}.fields.paymentMethods.${choice.code}`
                  )}
                </div>
              </Tooltip>
            );
          }}
          choices={unselectableChoices}
          disabled
        />
      )}
      <></>
      {paymentMethod && providerChoises.length && (
        <RadioButtonGroupInput
          source="paymentProvider"
          label="Proveedor de pago"
          optionText={(choice) => {
            return i18nProvider.translate(
              `resources.${PURCHASES}.fields.paymentProviders.${choice.code}`
            );
          }}
          optionValue="code"
          choices={providerChoises}
          disabled={!prePurchase || providerChoises.length === 1}
        />
      )}
    </>
  );
};

const ShippingInputs = () => {
  const ownShipping = useWatch({ name: "ownShipping" });

  return (
    <>
      <BooleanInput label="Costo de envío automático." source="ownShipping" />
      {!ownShipping && (
        <>
          <NumberInput source="shippingPrice" validate={[required()]} min={0} />
        </>
      )}
    </>
  );
};

const CouponInput = () => {
  return (
    <TextInput
      source="couponCode"
      inputProps={{ style: { textTransform: "uppercase" } }}
    />
  );
};

const ProductLimits = () => {
  const prePurchase = useAtomValue(prePurchaseAtom);
  const { isValid } = useFormState();

  return (
    <Box mt={4}>
      <Typography variant="h5" component="h5" mb={2}>
        Resumen productos
      </Typography>
      {!(prePurchase?.productLimits && prePurchase.productLimits.length > 0) ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          {isValid ? (
            <Refresh fontSize="large" className={"rotate-icon"} />
          ) : (
            <WidgetsOutlined fontSize="large" />
          )}
        </Box>
      ) : (
        <div>
          {prePurchase.productLimits.map((limit) => (
            <Accordion key={limit.data.id} expanded>
              <AccordionSummary>
                <Stack width="100%">
                  <LimitHeader limit={limit} />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <PurchaseProductBrandArrayInput products={limit.products} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </Box>
  );
};

const DetailsWidget = () => {
  const prePurchase = useAtomValue(prePurchaseAtom);
  const error = useAtomValue(prePurchaseErrorAtom);
  const { isValid } = useFormState();
  const { setValue } = useFormContext();

  const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
    <>
      <Typography variant="h5" component="h5" mb={2}>
        Resumen compra
      </Typography>
      <Box p={1}>{children}</Box>
    </>
  );

  if (error) {
    return (
      <Wrapper>
        <div>
          <p>
            Código de error:{" "}
            <b>
              {error instanceof AxiosError
                ? error.response?.data?.code ?? error.response?.status
                : "Sin conexión"}
            </b>
          </p>
          <p>
            {error instanceof AxiosError
              ? error.response?.data?.message
              : "Ocurrió un error."}
          </p>
          {error.response?.data?.code === "GEN_ALL_02" && (
            <Button
              onClick={() => setValue("couponCode", "")}
              label="Quitar cupón"
              variant="outlined"
            />
          )}
        </div>
      </Wrapper>
    );
  }

  if (!prePurchase && isValid) {
    return (
      <Wrapper>
        <Box sx={{ textAlign: "center" }}>
          <Refresh fontSize="large" className={"rotate-icon"} />
        </Box>
      </Wrapper>
    );
  }

  if (!prePurchase) return null;

  return <Pricing purchase={prePurchase} />;
};
